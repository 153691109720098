.column-container {
  background-color: $light-grey;
  border: 1px solid $light-grey;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: $s-size;
  height: fit-content;
  max-height: 90vh;
  min-width: 30rem;
  // overflow: scroll;
  overflow-y: auto;
}

.column-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.column-container::-webkit-scrollbar:vertical {
  width: 11px;
}

.column-container::-webkit-scrollbar:horizontal {
  height: 11px;
}

.column-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid $light-grey; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.column-title {
  margin: 0;
  padding: $xs-size;
  text-align: center;
  word-break: break-all;
}

.column-list {
  background-color: $light-grey;
  flex-grow: 1;
  min-height: 40rem;
  padding: 8px;
  transition: background-color 0.2s ease;
}

.column-list-dragging {
  @extend .column-list;
  background-color: $light-blue;
}

.buttons-column {
  display: flex;
  justify-content: center;
  margin: $xs-size;
}
