.project-board-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: $s-size;
}

.project-board-title {
  font-size: $xl-size;
  margin: 0;
  word-break: break-all;
}

.project-board-buttons {
  display: flex;
  justify-content: center;
}
