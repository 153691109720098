.box-layout {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.box-layout__box {
  align-items: center;
  background: fade-out(white, 0.15);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: $l-size $m-size;
  text-align: center;
  width: 50rem;
}

.box-layout__title {
  margin: 0 0 $m-size 0;
  line-height: 1;
}

.login-button {
  align-items: center;
  background: $purple;
  border: none;
  color: white;
  display: flex;
  font-size: $font-size-large;
  font-weight: 300;
  justify-content: space-evenly;
  margin: $xs-size;
  padding: $xs-size;
  text-decoration: none;
  width: 25rem;
  img {
    height: 3rem;
    width: 3rem;
  }
}

.login-button-title {
  align-items: center;
  margin: 0;
  text-align: center;
}
