.project-list {
  display: flex;
  flex-direction: column;
  margin-bottom: $l-size;
}

.project-list-header {
  align-items: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
  }
}

.project-list-items {
  border: 1px solid $dark-grey;
}
