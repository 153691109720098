//Colours
$dark-grey: #333333;
$grey: #666;
$green: #228b22;
$blue: #1c88bf;
$light-blue: #06aed5;
$red: #c40233;
$light-grey: #c0c0c0;
$yellow: #ffd700;
$purple: #4357ad;
$dark-blue: #364051;
$off-white: #f7f7f7;
// Font size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$xs-size: 1.2rem;
$s-size: 2rem;
$m-size: 2.4rem;
$l-size: 2.8rem;
$xl-size: 3.2rem;
$xxl-size: 4.8rem;
$desktop-breakpoint: 45rem;
