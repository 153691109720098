.button-add {
  background: $blue;
  border: none;
  color: white;
  display: inline-block;
  font-size: $font-size-large;
  font-weight: 300;
  margin: $xl-size;
  padding: $s-size;
  text-decoration: none;
  width: 20rem;
}

.button-add-small {
  @extend .button-add;
  height: 4rem;
  margin: 0 4px;
  padding: 0;
  width: 4rem;
}

.button-edit {
  @extend .button-add;
  background: $yellow;
}

.button-edit-small {
  @extend .button-add-small;
  background: $yellow;
}

.button-remove {
  @extend .button-add;
  background: $red;
}
.button-remove-small {
  @extend .button-add-small;
  background: $red;
}
.button-add-column {
  @extend .button-add;
  margin: 0;
}

.button-confirm-small {
  @extend .button-add-small;
  background: $green;
}
