.task-container {
  background-color: $off-white;
  border: 1px solid lightgrey;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: $xs-size;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
}
.task-title {
  text-align: center;
  font-size: $m-size;
  padding: 0;
  margin: $xs-size;
  word-break: break-all;
}
.task-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.task-item-buttons {
  display: flex;
  justify-content: center;
}

.drag-handler {
  margin-right: $s-size;
}

.task-description {
  font-size: $s-size;
  word-break: break-all;
}
