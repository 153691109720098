* {
    box-sizing: border-box; // This is the padding + height + margin to equal the size of the box
}


html {
    font-size: 62.5%;
}

body {
    background: $off-white;
    color: $dark-grey;
    font-family: Helvetica, Arial, sans-serif;
    font-size: $s-size;
    margin: 0;
    line-height: 1.6;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.is-active {
    font-weight: bold;
}